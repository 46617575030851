@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary bg-gray-200;
  }
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-violet-700;
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-violet-700 ml-auto;
  }
  .dropdown-menu {
    @apply px-6 py-8 text-[15px] space-y-6 shadow-md bg-white absolute w-full list-none rounded-b-lg z-50;
  }
}
